import { customScreenLotesApi } from "../../../services/api";


export const getLotes = async (codLote: number, apiUser: string, apiPassword: string) => {
    const response = await customScreenLotesApi.get('/upload', {
        headers: {
            cod_lote: codLote || "",
            api_user: apiUser || "",
            api_password: apiPassword || ""
        }
    })
    return response
}