import { Button, Dialog } from "@mui/material";
import { Header } from "../../Header";
import { FeriasModal } from "./Ferias";
import { FinanceiroModal } from "./FinanceiroModal";
import { FolhaDePagamentoModal } from "./FolhaDePagamentoModal";
import { RescisaoModal } from "./RescisaoModal";
import "./styles.scss";
import { TesourariaModal } from "./TesourariaModal";
import { useSicap } from "./useSicap";

export function ImportaçãoSicap() {
  const {
    setShowFinanceiroModal,
    showFinanceiroModal,
    setShowDocumentoModal,
    showDocumentoModal,
    setShowFolhaPagModal,
    showFolhaPagModal,
    setShowFeriasModal,
    showFeriasModal,
    showRescisaoModal,
    setShowRescisaoModal,
  } = useSicap();
  return (
    <div className="importacoes-sicap-container">
      <Header message={"Dashboard/Financeiro Sicap"} showAtualizacao={true} />
      <main>
        <div className="importacoes-sicap-main-card">
          <h2>Faturamento</h2>

          <div className="importacoes-sicap-faturamento-card">
            <Button
              variant="contained"
              onClick={() => setShowFinanceiroModal(true)}
            >
              Importar Financeiro
            </Button>
            <Dialog
              open={showFinanceiroModal}
              onClose={() => setShowFinanceiroModal(false)}
              maxWidth="md"
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <FinanceiroModal
                handleCloseModal={() => setShowFinanceiroModal(false)}
              />
            </Dialog>
            <Button
              variant="contained"
              onClick={() => setShowDocumentoModal(true)}
            >
              Importar Tesouraria
            </Button>
            <Dialog
              open={showDocumentoModal}
              onClose={() => setShowDocumentoModal(false)}
              maxWidth="md"
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <TesourariaModal
                handleCloseModal={() => setShowDocumentoModal(false)}
              />
            </Dialog>
          </div>
          <h2>Recursos Humanos</h2>

          <div className="importacoes-sicap-recursos-card">
            <Button
              variant="contained"
              disabled
              onClick={() => setShowFolhaPagModal(true)}
            >
              Folha de Pagamento
            </Button>
            <Dialog
              open={showFolhaPagModal}
              onClose={() => setShowFolhaPagModal(false)}
              maxWidth="md"
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <FolhaDePagamentoModal
                handleCloseModal={() => setShowFolhaPagModal(false)}
              />
            </Dialog>
            <Button
              variant="contained"
              onClick={() => setShowFeriasModal(true)}
              disabled
            >
              Férias
            </Button>
            <Dialog
              open={showFeriasModal}
              onClose={() => setShowFeriasModal(false)}
              maxWidth="md"
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <FeriasModal handleCloseModal={() => setShowFeriasModal(false)} />
            </Dialog>
            <Button
              variant="contained"
              onClick={() => setShowRescisaoModal(true)}
              disabled
            >
              Rescisão
            </Button>
            <Dialog
              open={showRescisaoModal}
              onClose={() => setShowRescisaoModal(false)}
              maxWidth="md"
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <RescisaoModal
                handleCloseModal={() => setShowRescisaoModal(false)}
              />
            </Dialog>
          </div>
        </div>
      </main>
    </div>
  );
}
