import { Button } from "@mui/material";
import { Header } from "../../Header";
import "./styles.scss";
import { useLotes } from "./useLotes";
export function LotesUnifacisa() {
    const { handleGetLotes, setCodLote, handleResponse } = useLotes()
    return (
        <div className="lotes-container">
            <Header
                message={"Dashboard/Lotes Unifacisa"}
                showAtualizacao={true}
            />
            <main>
                <div className="lotes-main-card">
                    <h2>Envio do Lote</h2>
                    <section className="lotes-main-card-section">
                        <input type="text" onBlur={(e) => setCodLote(Number(e.target.value))} />
                        <Button variant="contained"
                            onClick={() => handleGetLotes()}
                        >Enviar</Button>
                    </section>
                    {handleResponse !== "" && (
                        <div className="lotes-main-card-message">
                            <p><b>Mensagem:</b> {handleResponse}</p>
                        </div>
                    )}
                </div>
            </main>

        </div>
    )
}