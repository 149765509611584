import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  getDeparaGeneroEmployees,
  getDeparaRegimeContratacaoEmployees,
  getDeparaTipoAtividadeEmployees,
  getDeparaTipoColaboradorEmployees,
  getDeparaTipoFolhaEmployees,
  postEmployees,
  putEmployees,
} from "../../../../../Controllers/CustomScreens/Employees/EmployeesController";
import { ContentProps } from "../useEmployees";
import { EmployeesSchema, EmployeesSchemaProps } from "./schema";
interface DeparaProps {
  cdDepara: number;
  cdDeparaOrigem: string;
  cdDeparaSicap: string;
  dsDeparaOrigem: string;
  dsDeparaSicap: string;
  parceriaId: number;
  tpDepara: string;
}
interface UseModalEmployeesProps {
  handleCloseModal: () => void;
  handleGetEmployees: () => void;
  selectedEmployees: ContentProps;
  prestacaoContaId: number;
}
export const useModalEmployees = ({
  handleCloseModal,
  handleGetEmployees,
  selectedEmployees,
  prestacaoContaId,
}: UseModalEmployeesProps) => {
  const methods = useForm<EmployeesSchemaProps>({
    resolver: zodResolver(EmployeesSchema),
    defaultValues: {
      dataAdmissao:
        selectedEmployees.dataAdmissao === null ||
        selectedEmployees.dataAdmissao === undefined ||
        selectedEmployees.dataAdmissao === ""
          ? ""
          : dayjs(selectedEmployees.dataAdmissao).format("YYYY-MM-DD"),
      dataDemissao:
        selectedEmployees.dataDemissao === null ||
        selectedEmployees.dataDemissao === undefined ||
        selectedEmployees.dataDemissao === ""
          ? ""
          : dayjs(selectedEmployees.dataDemissao).format("YYYY-MM-DD"),
      dataNascimento:
        selectedEmployees.dataNascimento === null ||
        selectedEmployees.dataNascimento === undefined ||
        selectedEmployees.dataNascimento === ""
          ? ""
          : dayjs(selectedEmployees.dataNascimento).format("YYYY-MM-DD"),
      parceriaId:
        selectedEmployees.parceriaId !== undefined ||
        selectedEmployees.parceriaId !== null
          ? selectedEmployees.parceriaId
          : null,
      prestacaoContaId:
        selectedEmployees.prestacaoContaId !== undefined &&
        selectedEmployees.prestacaoContaId !== null
          ? selectedEmployees.prestacaoContaId
          : prestacaoContaId,
    },
  });

  async function handleSubmitForm(form: EmployeesSchemaProps) {
    if (selectedEmployees.cdFuncionario === undefined) {
      try {
        await postEmployees(form);
        handleGetEmployees();
        handleCloseModal();
        methods.reset();
        toast.success("Cadastro realizado com sucesso!");
      } catch (error) {
        toast.error(
          "Erro ao cadastrar funcionário, por favor verifique os campos preenchidos!"
        );
      }
    } else {
      try {
        await putEmployees(form, selectedEmployees);
        handleGetEmployees();
        handleCloseModal();
        methods.reset();
        toast.success("Cadastro editado com sucesso!");
      } catch (error) {
        toast.error(
          "Erro ao editar funcionário, por favor verifique os campos preenchidos!"
        );
      }
    }
  }

  const [deparaGenero, setDeparaGenero] = useState<DeparaProps[]>([]);
  const [deparaTipoAtividade, setDeparaTipoAtividade] = useState<DeparaProps[]>(
    []
  );
  const [deparaTipoColaborador, setDeparaTipoColaborador] = useState<
    DeparaProps[]
  >([]);
  const [deparaTipoFolha, setDeparaTipoFolha] = useState<DeparaProps[]>([]);
  const [deparaRegimeContratacao, setDeparaRegimeContratacao] = useState<
    DeparaProps[]
  >([]);
  async function handleGetDeparaGenero() {
    try {
      const { data } = await getDeparaGeneroEmployees();
      setDeparaGenero(data);
    } catch (error) {}
  }
  async function handleGetTipoAtividade() {
    try {
      const { data } = await getDeparaTipoAtividadeEmployees();
      setDeparaTipoAtividade(data);
    } catch (error) {}
  }

  async function handleGetTipoColaborador() {
    try {
      const { data } = await getDeparaTipoColaboradorEmployees();
      setDeparaTipoColaborador(data);
    } catch (error) {}
  }
  async function handleGetTipoFolha() {
    try {
      const { data } = await getDeparaTipoFolhaEmployees();
      setDeparaTipoFolha(data);
    } catch (error) {}
  }
  async function handleGetDeparaRegimeContratacao() {
    try {
      const { data } = await getDeparaRegimeContratacaoEmployees();
      setDeparaRegimeContratacao(data);
    } catch (error) {}
  }
  useEffect(() => {
    handleGetDeparaGenero();
    handleGetTipoAtividade();
    handleGetTipoColaborador();
    handleGetTipoFolha();
    handleGetDeparaRegimeContratacao();
  }, []);
  return {
    handleSubmitForm,
    methods,
    deparaGenero,
    deparaTipoAtividade,
    deparaTipoColaborador,
    deparaTipoFolha,
    deparaRegimeContratacao,
  };
};
