import { useEffect, useState } from "react";
import {
  getAccountability,
  putFinishAccountability,
} from "../../../../Controllers/CustomScreens/Accountability/AccountabilityController";

interface AccountabilityProps {
  content: ContentProps[];
  totalPages: number;
}
export interface ContentProps {
  cdPrestacao: number;
  dsCompetencia: string;
  dsDescricao: string;
  prestacaoContaId: number;
  tpStatus: string;
  dsErro: string;
}
export const useAccountability = () => {
  const [accountability, setAccountability] = useState<AccountabilityProps>(
    {} as AccountabilityProps
  );
  const [selectedAccountability, setSelectedAccountability] =
    useState<ContentProps>({} as ContentProps);
  const [showModalImport, setShowModalImport] = useState(false);

  async function handleGetAccountability(page?: number) {
    try {
      const { data } = await getAccountability(page);
      setAccountability(data);
    } catch (error) {}
  }

  useEffect(() => {
    handleGetAccountability();
  }, []);

  async function handlePaginate(
    event: React.ChangeEvent<unknown>,
    value: number
  ) {
    handleGetAccountability(value - 1);
  }

  function handleSelectFinishAccountability(account: ContentProps) {
    if (selectedAccountability.cdPrestacao === undefined) {
      setSelectedAccountability(account);
    } else {
      setSelectedAccountability({} as ContentProps);
    }
  }
  async function handleFinishAccountability() {
    try {
      await putFinishAccountability(selectedAccountability);
      setSelectedAccountability({} as ContentProps);
      await handleGetAccountability();
    } catch (err) {}
  }
  return {
    handleGetAccountability,
    accountability,
    handlePaginate,
    handleFinishAccountability,
    setSelectedAccountability,
    selectedAccountability,
    handleSelectFinishAccountability,
    showModalImport,
    setShowModalImport,
  };
};
