import { RxCross1 } from "react-icons/rx";
import "./styles.scss";
import { useTesourariaModal } from "./useTesourariaModal";
interface TesourariaModalProps {
  handleCloseModal: () => void;
}
export function TesourariaModal({ handleCloseModal }: TesourariaModalProps) {
  const {
    handleFileUpload,
    handleSendTesouraria,
    setTesourariaObject,
    tesourariaObject,
  } = useTesourariaModal({
    handleCloseModal,
  });
  return (
    <div className="tesouraria-modal-container">
      <header className="tesouraria-modal-header">
        <h2>Importar Tesouraria</h2>
        <RxCross1 onClick={() => handleCloseModal()} size={24} />
      </header>
      <main className="tesouraria-modal-main-container">
        <div className="tesouraria-modal-input-container">
          <p>Prestação</p>
          <input
            type="number"
            onBlur={(e) =>
              setTesourariaObject((prevState) => {
                return {
                  ...prevState,
                  prestacao: Number(e.target.value),
                };
              })
            }
          />
        </div>
        <div className="tesouraria-modal-input-container">
          <p>Competência</p>
          <input
            type="number"
            onBlur={(e) =>
              setTesourariaObject((prevState) => {
                return {
                  ...prevState,
                  competencia: Number(e.target.value),
                };
              })
            }
          />
        </div>
        <div className="tesouraria-modal-input-container">
          <p>Arquivo</p>
          <input
            type="file"
            id="sicap-document-file"
            multiple={false}
            accept=".xlsx"
            onChange={(e) => handleFileUpload(e.target.files)}
          />
          <label htmlFor="sicap-document-file">
            {tesourariaObject.fileName?.length > 18
              ? `${tesourariaObject.fileName?.slice(0, 18)}...`
              : tesourariaObject.fileName || ""}
          </label>
        </div>
      </main>
      <footer className="tesouraria-modal-footer">
        <button onClick={() => handleSendTesouraria()}>Confirmar</button>
        <button onClick={() => handleCloseModal()}>Cancelar</button>
      </footer>
    </div>
  );
}
