import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getInstallment } from "../../../../Controllers/CustomScreens/BankAccount/BankAccountController";
import {
  deletePayroll,
  getPayroll,
  putImportPayroll,
} from "../../../../Controllers/CustomScreens/Payroll/PayrollController";

export interface PayrollProps {
  content: ContentProps[];
  totalPages: number;
}
export interface ContentProps {
  plantaoMensal: number;
  abonoPecuniario: number;
  irrfSrpa: number;
  adcDecimoTerceiroFeriasPropResc: number;
  adiantamentoSalario: number;
  adicionalInsalubridade: number;
  adicionalNoturno: number;
  adicionalNoturnoDsr: number;
  adicionalPericulosidade: number;
  arredondamento: number;
  atestadoAteQuinzeDias: number;
  autoDeclaracaoGenero: string;
  autoDeclaracaoRacial: string;
  auxilioCreche: number;
  auxilios: number;
  baseFgts: number;
  baseFgtsDecimoTerceiro: number;
  baseFgtsDecimoTerceiroDemitidos: number;
  baseFgtsDemitidos: number;
  baseFgtsFerias: number;
  baseFgtsRescisao: number;
  baseInss: number;
  baseInssDecimoTerceiro: number;
  baseInssFerias: number;
  baseInssRescisao: number;
  baseIrrf: number;
  baseIrrfDecimoTerceiro: number;
  baseIrrfFerias: number;
  baseIrrfPlrAnual: number;
  baseIrrfRescisao: number;
  basePisDecimoTerceiroSemFolha: number;
  basePisFeriasSemFolha: number;
  basePisSemFolha: number;
  basePisRescisaoSemFolha: number;
  cdFolhaPagamento: number;
  cestaBasica: number;
  cnsProfissional: string;
  cpf: string;
  codigoCbo: string;
  dataAdmissao: string;
  dataDesligamento: string;
  dataNascimento: string;
  decimoTerPropRescisao: number;
  decimoTerceiroIndenizado: number;
  decimoTerceiroPrimeiraParcela: number;
  decimoTerceiroSegundaParcela: number;
  deducoesGps: number;
  desAdianDecimoTerceiro: number;
  desAdiantamentoSalario: number;
  desArredonSaldoDesconto: number;
  desAvisoPrevio: number;
  desContriSindicalAnual: number;
  desContriSindicalMensal: number;
  desEmprestimosConsignados: number;
  desMultaResAntecipado: number;
  desPrimeParcelaDecTer: number;
  desValetransIndivi: number;
  descontoInssRpa: number;
  descontosAntecipacaoFolha: number;
  descontosArredondamento: number;
  descontosAuxilios: number;
  descontosConvenios: number;
  descontosDsr: number;
  descontosEstorno: number;
  descontosFaltas: number;
  descontosInss: number;
  descontosInssDecimoTerceiro: number;
  descontosInssFerias: number;
  descontosInssRescisao: number;
  descontosIrrf: number;
  descontosIrrfDecimoTerceiro: number;
  descontosIrrfFerias: number;
  descontosIrrfRescisao: number;
  descontosMultaRescisoria: number;
  descontosNaoDedutiveis: number;
  descontosOutros: number;
  descontosPagamentoIndevido: number;
  descontosPlanoSaude: number;
  descontosSeguroVida: number;
  devolucao: number;
  diferencaSalarial: number;
  encargosSobreRescisao: number;
  especificacao: string;
  ferias: number;
  feriasAbono: number;
  feriasProporcionaisRescisao: number;
  feriasProximoMes: number;
  fgts: number;
  fgtsDecimoTerceiro: number;
  fgtsFerias: number;
  fgtsRescisao: number;
  fgtsRescisaoAntecipada: number;
  gratificacao: number;
  grrf: number;
  horasExtras: number;
  horasExtrasDsr: number;
  inssPatronal: number;
  inssRpaPatronal: number;
  inssTerceiros: number;
  issRpa: number;
  jornada: number;
  multaRescisao: number;
  nome: string;
  nomeSocial: string;
  numConselhoClasse: string;
  outrasVerbasRescisorias: number;
  outrosBeneficios: number;
  outrosProventos: number;
  parceriaId: number;
  pensaoAlimenticia: number;
  pisSemFolha: number;
  premio: number;
  prestacaoContaId: number;
  ratfap: number;
  regimeContratacao: string;
  restituicaoInss: number;
  restituicaoIrrf: number;
  salarioBase: number;
  salarioBruto: number;
  salarioFamilia: number;
  salarioLiquido: number;
  salarioMaternidade: number;
  saldoSalarioRescisao: number;
  tipoAtividade: string;
  tipoColaborador: string;
  tipoCoordenadoria: string;
  tipoFolha: string;
  tipoJornada: number;
  tpStatus: string;
  valeAlimentacao: number;
  valeRefeicao: number;
  valeTransporte: number;
  valorBrutoRescisao: number;
  valorBrutoRpa: number;
  valorLiquidoRescisaoApagar: number;
  valorLiquidoRpa: number;
  dsErro: string;
}
interface ErrorsImportRegistersProps {
  erros: string[];
  id: number;
  mensagem: string;
}
interface FilterRegisterProps {
  cpf: string;
  salario: string;
  unidade: string;
  prestacaoContaId: number;
}
export interface InstallmentListProps {
  prestacaoContaId: number;
  vigencia: string;
}
export const usePayroll = () => {
  const [selectedPayroll, setSelectedPayroll] = useState<ContentProps>(
    {} as ContentProps
  );

  const [modalState, setModalState] = useState<boolean>(false);
  const [payroll, setPayroll] = useState<PayrollProps>({} as PayrollProps);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [selectedImportRegisters, setSelectedImportRegisters] = useState<
    number[]
  >([]);
  const [page, setPage] = useState(0);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [filterRegisters, setFilterRegisters] = useState<FilterRegisterProps>(
    {} as FilterRegisterProps
  );

  const [installmentList, setInstallmentList] = useState<
    InstallmentListProps[]
  >([]);
  const [showModalExclude, setShowModalExclude] = useState<boolean>(false);
  const [bankToDelete, setBankToDelete] = useState(null);
  const [showModalImport, setShowModalImport] = useState(false);
  const [selectedInstallment, setSelectedInstallment] = useState<number>(0);

  async function handleGetPayroll(
    page?: number,
    cpf?: string,
    salario?: string,
    unidade?: string,
    prestacaoContaId?: number
  ) {
    setIsLoading(true);
    try {
      const { data } = await getPayroll(
        page,
        cpf,
        salario,
        unidade,
        prestacaoContaId
      );
      setPayroll(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  function handleEditModal(salary: ContentProps) {
    setSelectedPayroll(salary);
    setModalState(true);
  }

  async function handleDeletePayroll(cdFolhaPagamento: number) {
    try {
      await deletePayroll(cdFolhaPagamento);
      handleGetPayroll(
        page || 0,
        filterRegisters.cpf,
        filterRegisters.salario,
        filterRegisters.unidade,
        filterRegisters.prestacaoContaId || installmentList[0].prestacaoContaId
      );
      setShowModalExclude(false);
      setBankToDelete(null);
      toast.success("Registro deletado com sucesso");
    } catch (error) {
      toast.error("Erro ao deletar");
    }
  }

  async function getInstallmentList() {
    try {
      const { data } = await getInstallment();
      setInstallmentList(data);
      setSelectedInstallment(data[0].prestacaoContaId);
      handleGetPayroll(
        0,
        filterRegisters.cpf,
        filterRegisters.salario,
        filterRegisters.unidade,
        data[0].prestacaoContaId
      );
    } catch (err) {}
  }
  useEffect(() => {
    getInstallmentList();
  }, []);

  async function handlePaginate(
    event: React.ChangeEvent<unknown>,
    value: number
  ) {
    setPage(value - 1);
    handleGetPayroll(
      value - 1,
      filterRegisters.cpf,
      filterRegisters.salario,
      filterRegisters.unidade,
      filterRegisters.prestacaoContaId || installmentList[0].prestacaoContaId
    );
  }

  function handleImportRegisters(importRegisters?: number) {
    if (selectedImportRegisters.some((item) => item === importRegisters)) {
      // Se o objeto já está no array, remova-o
      setSelectedImportRegisters(
        selectedImportRegisters.filter((item) => item !== importRegisters)
      );
    } else {
      // Se o objeto não está no array, adicione-o
      setSelectedImportRegisters([...selectedImportRegisters, importRegisters]);
    }
  }

  async function handleSubmitImport() {
    try {
      const { data } = await putImportPayroll(
        allSelected ? [] : selectedImportRegisters
      );
      if (data === undefined) {
        toast.success("Folha de pagamento importadas com sucesso!!");
        await handleGetPayroll(
          page,
          filterRegisters.cpf,
          filterRegisters.salario,
          filterRegisters.unidade,
          filterRegisters.prestacaoContaId ||
            installmentList[0].prestacaoContaId
        );
        setSelectedImportRegisters([]);
        setAllSelected(false);
      } else {
        data.map((item: ErrorsImportRegistersProps) =>
          toast.warn(
            `Atenção, o registro ${
              item.mensagem
            } necessita do(s) campo(s): ${item.erros.map((error) => error)}`
          )
        );
        await handleGetPayroll(
          page,
          filterRegisters.cpf,
          filterRegisters.salario,
          filterRegisters.unidade,
          filterRegisters.prestacaoContaId ||
            installmentList[0].prestacaoContaId
        );
        setSelectedImportRegisters([]);
        setAllSelected(false);
      }
    } catch (error) {
      toast.error("Erro ao importar folha de pagamento");
    }
  }
  return {
    modalState,
    setModalState,
    payroll,
    handleEditModal,
    handleGetPayroll,
    handleDeletePayroll,
    selectedPayroll,
    setSelectedPayroll,
    handlePaginate,
    isLoading,
    handleImportRegisters,
    selectedImportRegisters,
    handleSubmitImport,
    page,
    allSelected,
    setAllSelected,
    setFilterRegisters,
    filterRegisters,
    installmentList,
    setShowModalExclude,
    showModalExclude,
    setBankToDelete,
    bankToDelete,
    showModalImport,
    setShowModalImport,
    selectedInstallment,
    setSelectedInstallment,
    setPayroll,
  };
};
