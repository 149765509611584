import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { toast } from "react-toastify";
import {
  postPositionSalary,
  putPositionSalary,
} from "../../../../../Controllers/CustomScreens/PositionSalary/PositionSalaryController";
import { ContentProps } from "../usePositionSalary";
import { PositionSalarySchema, PositionSalarySchemaProps } from "./schema";

interface UseModalPositionSalaryProps {
  handleCloseModal: () => void;
  handleGetPositionSalary: () => void;
  selectedPositionSalary: ContentProps;
  prestacaoContaId: number;
}
export const useModalPositionSalary = ({
  handleCloseModal,
  handleGetPositionSalary,
  selectedPositionSalary,
  prestacaoContaId,
}: UseModalPositionSalaryProps) => {
  const methods = useForm<PositionSalarySchemaProps>({
    resolver: zodResolver(PositionSalarySchema),
    defaultValues: {
      parceriaId:
        selectedPositionSalary.parceriaId !== undefined ||
        selectedPositionSalary.parceriaId !== null
          ? selectedPositionSalary.parceriaId
          : null,
      prestacaoContaId:
        selectedPositionSalary.prestacaoContaId !== undefined &&
        selectedPositionSalary.prestacaoContaId !== null
          ? selectedPositionSalary.prestacaoContaId
          : prestacaoContaId,
    },
  });

  async function handleSubmitForm(form: PositionSalarySchemaProps) {
    if (selectedPositionSalary.cdCargoSalario === undefined) {
      try {
        await postPositionSalary(form);
        methods.reset();
        handleCloseModal();
        handleGetPositionSalary();
        toast.success("Cargos e salários cadastrado com sucesso!");
      } catch (error) {
        toast.error(
          "Erro ao cadastrar Cargos e salários, por favor verifique os campos preenchidos!"
        );
      }
    } else {
      try {
        await putPositionSalary(form, selectedPositionSalary);
        methods.reset();
        handleCloseModal();
        handleGetPositionSalary();
        toast.success("Cargos e salários editado com sucesso!");
      } catch (error) {
        toast.error(
          "Erro ao editar cargos e salários, por favor verifique os campos preenchidos!"
        );
      }
    }
  }

  return { handleSubmitForm, methods };
};
