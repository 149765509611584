import { useState } from "react";
import { toast } from "react-toastify";
import {
  getFinancialRelease,
  postImportFinancialRelease,
} from "../../../../../Controllers/CustomScreens/FinancialRelease/FinancialReleaseController";
import { ImportConfig } from "../../BankAccount/ModalImport/useModalImport";
import {
  FinancialReleaseProps,
  InstallmentListProps,
} from "../useFinancialRelease";

interface UseModalImportProps {
  handleCloseModal: () => void;
  setNewList: React.Dispatch<React.SetStateAction<FinancialReleaseProps>>;
  installmentList?: InstallmentListProps[];
}
export const useModalImport = ({
  handleCloseModal,
  setNewList,
  installmentList,
}: UseModalImportProps) => {
  const [importConfig, setImportConfig] = useState<ImportConfig>();

  async function handleRefreshList() {
    try {
      const { data } = await getFinancialRelease(
        0,
        "",
        "",
        "",
        "",
        importConfig?.prestacaoContaId || installmentList[0].prestacaoContaId
      );
      setNewList(data);
    } catch (error) {}
  }
  async function handleImport() {
    try {
      await postImportFinancialRelease(importConfig);
      toast.success("Importação realizada com sucesso!");
      handleCloseModal();
      handleRefreshList();
    } catch (error) {
      toast.error("Verifique e preencha todos os campos!");
    }
  }
  return {
    setImportConfig,
    importConfig,
    handleImport,
  };
};
