import { useState } from "react";
import { toast } from "react-toastify";
import {
  getPositionSalary,
  postImportPositionSalary,
} from "../../../../../Controllers/CustomScreens/PositionSalary/PositionSalaryController";
import { ImportConfig } from "../../BankAccount/ModalImport/useModalImport";
import {
  InstallmentListProps,
  PositionSalaryProps,
} from "../usePositionSalary";

interface UseModalImportProps {
  handleCloseModal: () => void;
  setNewList: React.Dispatch<React.SetStateAction<PositionSalaryProps>>;
  installmentList?: InstallmentListProps[];
}
export const useModalImport = ({
  handleCloseModal,
  setNewList,
  installmentList,
}: UseModalImportProps) => {
  const [importConfig, setImportConfig] = useState<ImportConfig>();

  async function handleNewList() {
    try {
      const { data } = await getPositionSalary(0, "", "");
      setNewList(data);
    } catch (error) {}
  }
  async function handleImport() {
    try {
      await postImportPositionSalary(importConfig);
      await handleNewList();
      handleCloseModal();
      toast.success("Importação realizada com sucesso!");
    } catch (error) {
      toast.error("Verifique e preencha todos os campos!");
    }
  }
  return {
    setImportConfig,
    importConfig,
    handleImport,
  };
};
