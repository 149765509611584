import {
  Checkbox,
  CircularProgress,
  Dialog,
  LinearProgress,
  Pagination,
} from "@mui/material";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { BsPencilSquare } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { VscDebugRestart } from "react-icons/vsc";
import ReactTooltip from "react-tooltip";
import iconOcultar from "../../../../assets/iconOcultar.svg";
import { Header } from "../../../Header";
import { ConfirmExclude } from "../ConfirmExclude";
import { ModalImport } from "./ModalImport";
import { ModalPayroll } from "./ModalPayroll";
import "./styles.scss";
import { ContentProps, usePayroll } from "./usePayroll";

export default function Payroll() {
  const {
    payroll,
    handlePaginate,
    selectedPayroll,
    handleGetPayroll,
    setSelectedPayroll,
    handleEditModal,
    modalState,
    setModalState,
    handleDeletePayroll,
    isLoading,
    handleImportRegisters,
    selectedImportRegisters,
    allSelected,
    handleSubmitImport,
    page,
    setAllSelected,
    setFilterRegisters,
    filterRegisters,
    installmentList,
    setShowModalExclude,
    showModalExclude,
    setBankToDelete,
    bankToDelete,
    showModalImport,
    setShowModalImport,
    selectedInstallment,
    setSelectedInstallment,
    setPayroll,
  } = usePayroll();

  return (
    <div className="Payroll-container">
      <Header message={"Dashboard/Folha de Pagamento"} showAtualizacao={true} />
      <main>
        <div className="Payroll-main-card">
          <h2>Folha de Pagamento</h2>

          <header className="Payroll-main-card-header">
            <aside className="Payroll-main-card-filters">
              <input
                type="text"
                placeholder="CPF"
                onBlur={(e) =>
                  setFilterRegisters((prevState) => ({
                    ...prevState,
                    cpf: e.target.value,
                  }))
                }
              />
              <input
                type="number"
                placeholder="Salário"
                onBlur={(e) =>
                  setFilterRegisters((prevState) => ({
                    ...prevState,
                    salario: e.target.value,
                  }))
                }
              />{" "}
              <input
                type="text"
                placeholder="Unidade"
                onBlur={(e) =>
                  setFilterRegisters((prevState) => ({
                    ...prevState,
                    unidade: e.target.value,
                  }))
                }
              />
              <select
                onChange={(e) => {
                  setFilterRegisters({
                    ...filterRegisters,
                    prestacaoContaId: Number(e.target.value),
                  });
                  setSelectedInstallment(Number(e.target.value));
                }}
              >
                {installmentList.map((installment) => (
                  <option
                    value={installment.prestacaoContaId}
                    key={installment.prestacaoContaId}
                  >
                    {installment.vigencia}
                  </option>
                ))}
              </select>
              <button
                type="button"
                className="filter-button"
                onClick={() =>
                  handleGetPayroll(
                    0,
                    filterRegisters.cpf,
                    filterRegisters.salario,
                    filterRegisters.unidade,
                    filterRegisters.prestacaoContaId ||
                    installmentList[0].prestacaoContaId
                  )
                }
              >
                Filtrar
              </button>
            </aside>
            <div className="header-buttons">
              <aside>
                <Button
                  variant="contained"
                  onClick={() => setShowModalImport(true)}
                >
                  Importar Totvs <FaPlus />
                </Button>
              </aside>
              <aside>
                <Button variant="outlined" onClick={() => setModalState(true)}>
                  Novo Registro <FaPlus />
                </Button>
              </aside>
              <aside>
                <Button
                  variant="contained"
                  onClick={() => handleSubmitImport()}
                  disabled={!allSelected && selectedImportRegisters.length <= 0}
                >
                  Enviar Sicap <FaPlus />
                </Button>
                <Checkbox
                  color="primary"
                  size="small"
                  title="Todos Registros"
                  checked={allSelected}
                  onChange={() => setAllSelected(!allSelected)}
                />{" "}
                Todos Registros
              </aside>
              <div className="header-buttons-restart">
                {isLoading ? (
                  <CircularProgress
                    style={{
                      color: "white",
                      animation: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                  />
                ) : (
                  <VscDebugRestart
                    data-tip="Atualizar"
                    onClick={() =>
                      handleGetPayroll(
                        page,
                        filterRegisters.cpf,
                        filterRegisters.salario,
                        filterRegisters.unidade,
                        filterRegisters.prestacaoContaId ||
                        installmentList[0].prestacaoContaId
                      )
                    }
                  />
                )}
              </div>
            </div>
          </header>
          <section className="Payroll-main-card-section">
            <Dialog
              open={modalState}
              onClose={() => (
                setModalState(false), setSelectedPayroll({} as ContentProps)
              )}
              maxWidth="lg"
              fullWidth={true}
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <ModalPayroll
                handleCloseModal={() => (
                  setModalState(false), setSelectedPayroll({} as ContentProps)
                )}
                prestacaoContaId={selectedInstallment}
                selectedPayroll={selectedPayroll}
                isEditing={
                  selectedPayroll.cdFolhaPagamento !== undefined ? true : false
                }
                handleGetPayroll={() =>
                  handleGetPayroll(
                    page,
                    filterRegisters.cpf,
                    filterRegisters.salario,
                    filterRegisters.unidade,
                    filterRegisters.prestacaoContaId ||
                    installmentList[0].prestacaoContaId
                  )
                }
              />
            </Dialog>
            <Dialog
              open={showModalExclude}
              onClose={() => setShowModalExclude(false)}
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <ConfirmExclude
                handleCloseModal={() => setShowModalExclude(false)}
                handleSubmit={() =>
                  handleDeletePayroll(bankToDelete.cdFolhaPagamento)
                }
              />
            </Dialog>
            <Dialog
              open={showModalImport}
              onClose={() => setShowModalImport(false)}
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <ModalImport
                handleCloseModal={() => setShowModalImport(false)}
                installmentList={installmentList}
                setNewList={setPayroll}
              />
            </Dialog>
            {!isLoading ? (
              <table>
                <thead>
                  <tr>
                    <th colSpan={1}></th>
                    <th>Status</th>

                    <th>Nome</th>
                    <th>Data admissão</th>
                    <th>Data nascimento</th>
                    <th>Data desligamento</th>
                    <th>Salario base</th>
                    {/* <th>Gênero</th> */}
                    <th>Cpf</th>
                    <th>Tipo colaborador</th>
                    <th colSpan={1}></th>
                  </tr>
                </thead>
                <tbody>
                  {payroll?.content?.map((payroll, index) => (
                    <tr key={index}>
                      <td>
                        {(payroll.tpStatus === "A" ||
                          payroll.tpStatus === "E") && (
                            <Checkbox
                              color="primary"
                              size="small"
                              onChange={() => {
                                handleImportRegisters(payroll.cdFolhaPagamento);
                              }}
                              checked={selectedImportRegisters.some(
                                (item) => item === payroll.cdFolhaPagamento
                              )}
                            />
                          )}
                      </td>
                      <td>
                        {payroll.tpStatus === "A" && (
                          <div className="circle-status">
                            <span
                              data-tip="Aguardando"
                              className="circle-aguardando"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                        {payroll.tpStatus === "I" && (
                          <div className="circle-status">
                            <span
                              data-tip="Importado"
                              className="circle-importado"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                        {payroll.tpStatus === "T" && (
                          <div className="circle-status">
                            <span
                              data-tip="Transferido"
                              className="circle-transferido"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                        {payroll.tpStatus === "E" && (
                          <div className="circle-status">
                            <span
                              data-tip={payroll.dsErro}
                              className="circle-error"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                        {payroll.tpStatus === "F" && (
                          <div className="circle-status">
                            <span
                              data-tip="Finalizado"
                              className="circle-finalizado"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                      </td>
                      <td>{payroll.nome}</td>
                      <td>
                        {payroll.dataAdmissao === null
                          ? ""
                          : dayjs(payroll.dataAdmissao).format("DD/MM/YYYY")}
                      </td>
                      <td>
                        {payroll.dataNascimento === null
                          ? ""
                          : dayjs(payroll.dataNascimento).format("DD/MM/YYYY")}
                      </td>
                      <td>
                        {payroll.dataDesligamento === null
                          ? ""
                          : dayjs(payroll.dataDesligamento).format(
                            "DD/MM/YYYY"
                          )}
                      </td>
                      <td>{payroll.salarioBase}</td>
                      {/* <td>{payroll.genero}</td> */}
                      <td>{payroll.cpf}</td>
                      <td>{payroll.tipoColaborador}</td>
                      <td>
                        <div className="Payroll-main-card-section-icons">
                          <p
                            data-tip="Editar Registro"
                            onClick={() => handleEditModal(payroll)}
                          >
                            <BsPencilSquare color="#D8A511" />
                          </p>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                          <p
                            data-tip="Excluir Registro"
                            onClick={() => {
                              setBankToDelete(payroll);
                              setShowModalExclude(true);
                            }}
                          >
                            <img src={iconOcultar} alt="" />
                          </p>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <LinearProgress />
            )}
          </section>
          <footer>
            <Pagination
              count={payroll.totalPages}
              variant="outlined"
              shape="rounded"
              onChange={handlePaginate}
            />
          </footer>
        </div>
      </main>
    </div>
  );
}
