import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  deleteBankAccount,
  getBankAccount,
  getInstallment,
  putImportBankAccount,
} from "../../../../Controllers/CustomScreens/BankAccount/BankAccountController";
export interface ContentProps {
  tblDtiCdBanco: number;
  id: string;
  contaContabilPai: string;
  contaContabil: string;
  dsContaBancaria: string;
  dsAgencia: string;
  dsAgenciaDigito: string;
  numeroContaBancaria: string;
  contaDigito: string;
  contaAplicacao: boolean;
  idFonteRecurso: number;
  dsFonteRecurso: string;
  idTipoRecurso: number;
  dsTipoRecurso: string;
  saldoAnterior: number;
  prestacaoContaId: number;
  tpStatus: string;
  dsErro: string;
  parceriaId: number;
}
export interface BankAccountListProps {
  content: ContentProps[];
  totalPages: number;
}

interface ErrorsImportRegistersProps {
  erros: string[];
  id: number;
  mensagem: string;
}

interface FilterRegisterProps {
  dsContaBancaria: string;
  contaContabil: string;
  prestacaoContaId: number;
}

export interface InstallmentListProps {
  prestacaoContaId: number;
  vigencia: string;
}
export const useBankAccount = () => {
  const [modalState, setModalState] = useState<boolean>(false);
  const [bankAccountList, setBankAccountList] = useState<BankAccountListProps>(
    {} as BankAccountListProps
  );
  const [tblDtiCdBanco, setTblDtiCdBanco] = useState<number>();
  const [selectedBankAccount, setSelectedBankAccount] = useState<ContentProps>(
    {} as ContentProps
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [selectedImportRegisters, setSelectedImportRegisters] = useState<
    number[]
  >([]);
  const [page, setPage] = useState(0);
  const [allSelected, setAllSelected] = useState<boolean>(false);

  const [filterRegisters, setFilterRegisters] = useState<FilterRegisterProps>(
    {} as FilterRegisterProps
  );

  const [installmentList, setInstallmentList] = useState<
    InstallmentListProps[]
  >([]);
  const [showModalExclude, setShowModalExclude] = useState<boolean>(false);
  const [bankToDelete, setBankToDelete] = useState(null);
  const [showModalImport, setShowModalImport] = useState(false);

  const [selectedInstallment, setSelectedInstallment] = useState<number>(0);
  async function handleGetBankAccount(
    page?: number,
    dsContaBancaria?: string,
    contaContabil?: string,
    prestacaoContaId?: number
  ) {
    setIsLoading(true);
    try {
      const { data } = await getBankAccount(
        page,
        dsContaBancaria,
        contaContabil,
        prestacaoContaId
      );
      setBankAccountList(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  function handleEditModal(bank: ContentProps) {
    setTblDtiCdBanco(bank.tblDtiCdBanco);
    setSelectedBankAccount(bank);
    setModalState(true);
  }

  async function handleDeleteBankAccount(tblDtiCdBanco: number) {
    try {
      await deleteBankAccount(tblDtiCdBanco);
      handleGetBankAccount(
        page || 0,
        filterRegisters.dsContaBancaria,
        filterRegisters.contaContabil,
        filterRegisters.prestacaoContaId || installmentList[0].prestacaoContaId
      );
      setShowModalExclude(false);
      setBankToDelete(null);
      toast.success("Conta deletada com sucesso");
    } catch (error) {
      toast.error("Erro ao deletar");
    }
  }

  async function getInstallmentList() {
    try {
      const { data } = await getInstallment();
      setInstallmentList(data);
      setSelectedInstallment(data[0].prestacaoContaId);
      handleGetBankAccount(
        0,
        filterRegisters.dsContaBancaria,
        filterRegisters.contaContabil,
        data[0].prestacaoContaId
      );
    } catch (err) {}
  }
  useEffect(() => {
    getInstallmentList();
  }, []);

  async function handlePaginate(
    event: React.ChangeEvent<unknown>,
    value: number
  ) {
    setPage(value - 1);
    handleGetBankAccount(
      value - 1,
      filterRegisters.dsContaBancaria,
      filterRegisters.contaContabil,
      filterRegisters.prestacaoContaId || installmentList[0].prestacaoContaId
    );
  }
  function handleImportRegisters(importRegisters?: number) {
    if (selectedImportRegisters.some((item) => item === importRegisters)) {
      // Se o objeto já está no array, remova-o
      setSelectedImportRegisters(
        selectedImportRegisters.filter((item) => item !== importRegisters)
      );
    } else {
      // Se o objeto não está no array, adicione-o
      setSelectedImportRegisters([...selectedImportRegisters, importRegisters]);
    }
  }

  async function handleSubmitImport() {
    try {
      const { data } = await putImportBankAccount(
        allSelected ? [] : selectedImportRegisters
      );
      if (data === undefined) {
        toast.success("Contas bancárias importadas com sucesso!!");
        await handleGetBankAccount(
          page,
          filterRegisters.dsContaBancaria,
          filterRegisters.contaContabil,
          filterRegisters.prestacaoContaId ||
            installmentList[0].prestacaoContaId
        );
        setSelectedImportRegisters([]);
        setAllSelected(false);
      } else {
        data.map((item: ErrorsImportRegistersProps) =>
          toast.warn(
            `Atenção, o registro ${
              item.id
            } necessita do(s) campo(s): ${item.erros.map((error) => error)}`
          )
        );
        await handleGetBankAccount(
          page,
          filterRegisters.dsContaBancaria,
          filterRegisters.contaContabil,
          filterRegisters.prestacaoContaId ||
            installmentList[0].prestacaoContaId
        );
        setSelectedImportRegisters([]);
        setAllSelected(false);
      }
    } catch (error) {
      toast.error("Erro ao importar contas bancárias");
    }
  }

  return {
    modalState,
    setModalState,
    bankAccountList,
    handleEditModal,
    tblDtiCdBanco,
    handleGetBankAccount,
    handleDeleteBankAccount,
    selectedBankAccount,
    setSelectedBankAccount,
    handlePaginate,
    isLoading,
    selectedImportRegisters,
    handleImportRegisters,
    handleSubmitImport,
    page,
    allSelected,
    setAllSelected,
    setFilterRegisters,
    filterRegisters,
    installmentList,
    setShowModalExclude,
    showModalExclude,
    setBankToDelete,
    bankToDelete,
    showModalImport,
    setShowModalImport,
    setSelectedInstallment,
    selectedInstallment,
    setBankAccountList,
  };
};
