import dayjs from "dayjs";
import { RxCross1 } from "react-icons/rx";
import "./styles.scss";
import { useFinanceiroModal } from "./useFinanceiroModal";

interface FinanceiroModalProps {
  handleCloseModal: () => void;
}
export function FinanceiroModal({ handleCloseModal }: FinanceiroModalProps) {
  const { handleSendFinanceiro, setFinanceiroObject } = useFinanceiroModal({
    handleCloseModal,
  });

  return (
    <div className="financeiro-modal-container">
      <header className="financeiro-modal-header">
        <h2>Importar Financeiro</h2>
        <RxCross1 onClick={() => handleCloseModal()} size={24} />
      </header>
      <main className="financeiro-modal-main-container">
        <div className="financeiro-modal-input-container">
          <p>Data Inicial</p>
          <input
            type="date"
            onBlur={(e) =>
              setFinanceiroObject((prevState) => {
                return {
                  ...prevState,
                  dtInicio: dayjs(e.target.value).format(
                    "YYYY-MM-DD[T]00:00:00"
                  ),
                };
              })
            }
          />
        </div>
        <div className="financeiro-modal-input-container">
          <p>Data Final</p>
          <input
            type="date"
            onBlur={(e) =>
              setFinanceiroObject((prevState) => {
                return {
                  ...prevState,
                  dtFim: dayjs(e.target.value).format("YYYY-MM-DD[T]00:00:00"),
                };
              })
            }
          />
        </div>
        <div className="financeiro-modal-input-container">
          <p>Competência</p>
          <input
            type="number"
            onBlur={(e) =>
              setFinanceiroObject((prevState) => {
                return {
                  ...prevState,
                  competencia: Number(e.target.value),
                };
              })
            }
          />
        </div>
        <div className="financeiro-modal-input-container">
          <p>Prestação</p>
          <input
            type="number"
            onBlur={(e) =>
              setFinanceiroObject((prevState) => {
                return {
                  ...prevState,
                  prestacao: Number(e.target.value),
                };
              })
            }
          />
        </div>
      </main>
      <footer className="financeiro-modal-footer">
        <button onClick={() => handleSendFinanceiro()}>Confirmar</button>
        <button onClick={() => handleCloseModal()}>Cancelar</button>
      </footer>
    </div>
  );
}
