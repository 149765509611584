import { useState } from "react";
import { toast } from "react-toastify";
import { getLotes } from "../../../Controllers/CustomScreens/Lotes/LotesController";

export const useLotes = () => {
  const [codLote, setCodLote] = useState<number>();
  const [handleResponse, setHandleResponse] = useState<string>("");

  async function handleGetLotes() {
    try {
      const { data } = await getLotes(
        codLote,
        "batchloaderUser",
        "UpIL0@dU$&rDt!"
      );
      if (data.status === 200) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setHandleResponse(data.message);
    } catch (err) {
      toast.error("Erro ao enviar requisição");
      setHandleResponse("Erro ao enviar requisição");
    }
  }

  return {
    handleGetLotes,
    setCodLote,
    handleResponse,
  };
};
