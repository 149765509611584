import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getInstallment } from "../../../../Controllers/CustomScreens/BankAccount/BankAccountController";
import {
  deleteEmployees,
  getEmployees,
  putImportEmployees,
} from "../../../../Controllers/CustomScreens/Employees/EmployeesController";

export interface EmployeesProps {
  content: ContentProps[];
  totalPages: number;
}
export interface ContentProps {
  cdFuncionario: number;
  nome: string;
  cpf: string;
  cns: string;
  valorSalarioBase: number;
  unidadeId: string;
  dataAdmissao: string;
  dataDemissao: string;
  linhaServicoId: number;
  jornada: number;
  cargoId: string;
  regimeContratacao: string;
  tipoAtividade: string;
  matricula: string;
  dataNascimento: string;
  genero: string;
  tipoColaborador: string;
  tipoFolha: string;
  cargaHorariaSemanal: number;
  tpStatus: string;
  dsErro: string;
  parceriaId: number;
  prestacaoContaId: number;
}

interface ErrorsImportRegistersProps {
  erros: string[];
  id: number;
  mensagem: string;
}
interface FilterRegisterProps {
  cpf: string;
  matricula: string;
  unidade: string;
}

export interface InstallmentListProps {
  prestacaoContaId: number;
  vigencia: string;
}
export const useEmployees = () => {
  const [modalState, setModalState] = useState<boolean>(false);
  const [employees, setEmployees] = useState<EmployeesProps>(
    {} as EmployeesProps
  );
  const [selectedEmployees, setSelectedEmployees] = useState<ContentProps>(
    {} as ContentProps
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [selectedImportRegisters, setSelectedImportRegisters] = useState<
    number[]
  >([]);

  const [page, setPage] = useState(0);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [filterRegisters, setFilterRegisters] = useState<FilterRegisterProps>(
    {} as FilterRegisterProps
  );
  const [selectedInstallment, setSelectedInstallment] = useState<number>(0);

  const [showModalExclude, setShowModalExclude] = useState<boolean>(false);
  const [employToDelete, setEmployToDelete] = useState(null);
  const [showModalImport, setShowModalImport] = useState(false);
  const [installmentList, setInstallmentList] = useState<
    InstallmentListProps[]
  >([]);

  async function handleGetEmployees(
    page?: number,
    cpf?: string,
    matricula?: string,
    unidade?: string
  ) {
    setIsLoading(true);
    try {
      const { data } = await getEmployees(page, cpf, matricula, unidade);
      setEmployees(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }
  async function getInstallmentList() {
    try {
      const { data } = await getInstallment();
      setInstallmentList(data);
      setSelectedInstallment(data[0].prestacaoContaId);
    } catch (err) {}
  }
  useEffect(() => {
    getInstallmentList();
  }, []);
  function handleEditModal(salary: ContentProps) {
    setSelectedEmployees(salary);
    setModalState(true);
  }

  async function handleDeleteEmployees(cdFuncionario: number) {
    try {
      await deleteEmployees(cdFuncionario);
      handleGetEmployees();
      toast.success("Registro deletado com sucesso");
      setEmployToDelete(null);
      setShowModalExclude(false);
    } catch (error) {
      toast.error("Erro ao deletar");
    }
  }
  useEffect(() => {
    handleGetEmployees();
  }, []);

  async function handlePaginate(
    event: React.ChangeEvent<unknown>,
    value: number
  ) {
    setPage(value - 1);
    handleGetEmployees(value - 1);
  }

  function handleImportRegisters(importRegisters?: number) {
    if (selectedImportRegisters.some((item) => item === importRegisters)) {
      // Se o objeto já está no array, remova-o
      setSelectedImportRegisters(
        selectedImportRegisters.filter((item) => item !== importRegisters)
      );
    } else {
      // Se o objeto não está no array, adicione-o
      setSelectedImportRegisters([...selectedImportRegisters, importRegisters]);
    }
  }

  async function handleSubmitImport() {
    try {
      const { data } = await putImportEmployees(
        allSelected ? [] : selectedImportRegisters
      );
      if (data === undefined) {
        toast.success("Funcionários importados com sucesso!!");
        await handleGetEmployees(page);
        setSelectedImportRegisters([]);
        setAllSelected(false);
      } else {
        data.map((item: ErrorsImportRegistersProps) =>
          toast.warn(
            `Atenção, o registro ${
              item.mensagem
            } necessita do(s) campo(s): ${item.erros.map((error) => error)}`
          )
        );
        await handleGetEmployees(page);
        setSelectedImportRegisters([]);
        setAllSelected(false);
      }
    } catch (error) {
      toast.error("Erro ao importar funcionários");
    }
  }

  return {
    modalState,
    setModalState,
    employees,
    handleEditModal,
    handleGetEmployees,
    handleDeleteEmployees,
    selectedEmployees,
    setSelectedEmployees,
    handlePaginate,
    isLoading,
    handleImportRegisters,
    selectedImportRegisters,
    handleSubmitImport,
    page,
    allSelected,
    setAllSelected,
    setFilterRegisters,
    filterRegisters,
    showModalExclude,
    setShowModalExclude,
    employToDelete,
    setEmployToDelete,
    showModalImport,
    setShowModalImport,
    installmentList,
    selectedInstallment,
    setSelectedInstallment,
    setEmployees,
  };
};
