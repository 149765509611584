import { useState } from "react";
import { toast } from "react-toastify";
import {
  getEmployees,
  postImportEmployees,
} from "../../../../../Controllers/CustomScreens/Employees/EmployeesController";
import { ImportConfig } from "../../BankAccount/ModalImport/useModalImport";
import { EmployeesProps, InstallmentListProps } from "../useEmployees";

interface UseModalImportProps {
  handleCloseModal: () => void;
  installmentList?: InstallmentListProps[];
  setNewList: React.Dispatch<React.SetStateAction<EmployeesProps>>;
}
export const useModalImport = ({
  handleCloseModal,
  setNewList,
}: UseModalImportProps) => {
  const [importConfig, setImportConfig] = useState<ImportConfig>();

  async function handleNewList() {
    try {
      const { data } = await getEmployees(0, "", "");
      setNewList(data);
    } catch (error) {}
  }

  async function handleImport() {
    try {
      await postImportEmployees(importConfig);
      await handleNewList();
      handleCloseModal();
      toast.success("Importação realizada com sucesso!");
    } catch (error) {
      toast.error("Verifique e preencha todos os campos!");
    }
  }
  return {
    setImportConfig,
    importConfig,
    handleImport,
  };
};
