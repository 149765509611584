import { createContext, ReactNode, useState } from "react";
import { FilterRegisterProps } from "../../../components/CustomScreens/totvs/FinancialRelease/useFinancialRelease";

interface FinanceReleaseContextProps {
    filterRegisters: FilterRegisterProps;
    setFilterRegisters: React.Dispatch<React.SetStateAction<FilterRegisterProps>>;
    selectedInstallment: number;
    setSelectedInstallment: React.Dispatch<React.SetStateAction<number>>;
}
export const FinanceReleaseContext = createContext(
    {} as FinanceReleaseContextProps
);

interface FinanceReleaseProviderProps {
    children: ReactNode;
}
export function FinanceReleaseProvider({
    children,
}: FinanceReleaseProviderProps) {
    const [filterRegisters, setFilterRegisters] = useState<FilterRegisterProps>(
        {} as FilterRegisterProps
    );
    const [selectedInstallment, setSelectedInstallment] = useState<number>(0);

    return (
        <FinanceReleaseContext.Provider
            value={{
                filterRegisters,
                setFilterRegisters,
                selectedInstallment,
                setSelectedInstallment
            }}
        >
            {children}
        </FinanceReleaseContext.Provider>
    );
}
