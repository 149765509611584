import { useState } from "react";
import { toast } from "react-toastify";
import { postSendTesouraria } from "../../../../Controllers/CustomScreens/Sicap/SicapController";

export interface TesourariaObjectProps {
  competencia: number;
  prestacao: number;
  file: File;
  fileName: string;
}

interface UseTesourariaModalProps {
  handleCloseModal: () => void;
}
export const useTesourariaModal = ({
  handleCloseModal,
}: UseTesourariaModalProps) => {
  const [tesourariaObject, setTesourariaObject] =
    useState<TesourariaObjectProps>({} as TesourariaObjectProps);

  async function handleFileUpload(file: FileList) {
    setTesourariaObject((prevState) => {
      return {
        ...prevState,
        file: file[0],
        fileName: file[0].name,
      };
    });
  }

  async function handleSendTesouraria() {
    try {
      await postSendTesouraria(tesourariaObject);
      toast.success("Requisição enviada com sucesso!");

      handleCloseModal();
    } catch (err) {
      toast.error("Erro, não foi possível conectar com o servidor do cliente");
    }
  }

  return {
    handleSendTesouraria,
    setTesourariaObject,
    handleFileUpload,
    tesourariaObject,
  };
};
