import { useState } from "react"

export const useSicap = () => {
    const [showFinanceiroModal, setShowFinanceiroModal] = useState<boolean>(false)
    const [showDocumentoModal, setShowDocumentoModal] = useState<boolean>(false)
    const [showFolhaPagModal, setShowFolhaPagModal] = useState<boolean>(false)
    const [showFeriasModal, setShowFeriasModal] = useState<boolean>(false)
    const [showRescisaoModal, setShowRescisaoModal] = useState<boolean>(false)

    return {
        showFinanceiroModal,
        setShowFinanceiroModal,
        showDocumentoModal,
        setShowDocumentoModal,
        setShowFolhaPagModal,
        showFolhaPagModal,
        setShowFeriasModal,
        showFeriasModal,
        showRescisaoModal,
        setShowRescisaoModal
    }
}