import { RxCross1 } from "react-icons/rx";
import "./styles.scss";
interface FeriasModalProps {
    handleCloseModal: () => void;

}
export function FeriasModal({ handleCloseModal }: FeriasModalProps) {
    return (
        <div className="ferias-modal-container">
            <header className="ferias-modal-header">
                <h2>Modal Férias</h2>
                <RxCross1 onClick={() => handleCloseModal()} size={24} />
            </header>
            <main className="ferias-modal-main-container">
                <div className="ferias-modal-input-container">
                    <p>Data Inicial</p>
                    <input type="date" />
                </div>
                <div className="ferias-modal-input-container">
                    <p>Valor</p>
                    <input type="text" />
                </div>
                <div className="ferias-modal-input-container">
                    <p>Data Final</p>
                    <input type="date" />
                </div>
            </main>
            <footer className="ferias-modal-footer">
                <button >
                    Confirmar
                </button>
                <button onClick={() => handleCloseModal()}>Cancelar</button>
            </footer>
        </div>
    )
}