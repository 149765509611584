import { FinanceiroObjectProps } from "../../../components/CustomScreens/sicap/FinanceiroModal/useFinanceiroModal";
import { TesourariaObjectProps } from "../../../components/CustomScreens/sicap/TesourariaModal/useTesourariaModal";
import { customScreenSicapApi } from "../../../services/api";

export const getSendFinanceiro = async (
  financeiroObject: FinanceiroObjectProps
) => {
  const response = await customScreenSicapApi.get(
    `/documentos/all?competencia=${financeiroObject.competencia}&prestacao=${financeiroObject.prestacao}&dtInicio=${financeiroObject.dtInicio}&dtFim=${financeiroObject.dtFim}`
  );
  return response;
};

export const postSendTesouraria = async (
  tesourariaObject: TesourariaObjectProps
) => {
  const formData = new FormData();

  formData.append("filename", tesourariaObject.file);

  const response = await customScreenSicapApi.post(
    `/documentos/tesouraria?prestacao=${tesourariaObject.prestacao}&competencia=${tesourariaObject.competencia}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response;
};
