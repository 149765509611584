import { useState } from "react";
import { toast } from "react-toastify";
import { getSendFinanceiro } from "../../../../Controllers/CustomScreens/Sicap/SicapController";

export interface FinanceiroObjectProps {
  competencia: number;
  prestacao: number;
  dtInicio: string;
  dtFim: string;
}

interface UseFinanceiroModalProps {
  handleCloseModal: () => void;
}
export const useFinanceiroModal = ({
  handleCloseModal,
}: UseFinanceiroModalProps) => {
  const [financeiroObject, setFinanceiroObject] =
    useState<FinanceiroObjectProps>({} as FinanceiroObjectProps);

  async function handleSendFinanceiro() {
    try {
      await getSendFinanceiro(financeiroObject);
      toast.success("Requisição enviada com sucesso!");
      handleCloseModal();
    } catch (err) {
      toast.error("Erro, não foi possível conectar com o servidor do cliente");
    }
  }

  return {
    handleSendFinanceiro,
    setFinanceiroObject,
  };
};
